import React from 'react'
import i18n from 'i18next'
import NoUserImg from '../../../../img/noImgUser.png'
import StaigeButton from '../../../../components/styles/Button'
import addUserIcon from '../../../../img/icons/addUser.svg'
import searchIcon from '../../../../img/icons/search2.svg'

import { ContractContainer, UserData } from '../../../../services/contractContainer'
import { UserContainer } from '../../../../services/userContainer'
import { Contract, License } from '@soccerwatch/common'

import './UserSelect.scss'
import StaigeInput from '../../../../components/styles/Input'

const relevantLicences: Record<string, string> = {
  AdManagerAccess: 'AdManagerAccess',
  CameraLicense: 'CameraLicense',
  TrainerSubscription: 'TrainerSubscription',
  ContractOwnerLicense: 'ContractOwnerLicense',
  CommentatorLicense: 'CommentatorLicense',
  ClubTaggerLicense: 'ClubTaggerLicense',
  ClubStreamerLicense: 'ClubStreamerLicense'
}

type UserSelectPropTypes = {
  contractContainer: ContractContainer
  userContainer: UserContainer
  contracts: Contract[]
  contractSubUser: Record<string, UserData[]>
  loadingData: boolean
  handleOpenUser: (user: UserData | undefined) => void
  handleNewUser: (user: boolean) => void
  user: any | undefined
}

type UserSelectStateTypes = {
  searchInput: string
}

export default class UserSelect extends React.Component<UserSelectPropTypes, UserSelectStateTypes> {
  constructor(props: UserSelectPropTypes) {
    super(props)
    this.state = {
      searchInput: ''
    }
  }

  getUserRolesString = (licenses: License[]) => {
    const userRoles = Object.keys(
      licenses.reduce((roles, l) => {
        // Map License to I18N-Rolename. Reduce is used to only keep Unique entries
        if (relevantLicences[l.type]) {
          const licenseRole = i18n.t(relevantLicences[l.type])
          if (!roles[licenseRole]) {
            roles[licenseRole] = true
          }
        }
        return roles
      }, {} as Record<string, boolean>)
    )
    userRoles.sort(function (a, b) {
      if (a < b || a === 'ContractOwnerLicense') {
        return -1
      }
      if (a > b) {
        return 1
      }
      return 0
    })
    return userRoles
  }

  shortNameExtration(name: string) {
    name = name.trim()
    const words = name.split(' ')
    let shortName = ''
    for (let i = 0; i < words.length; i++) {
      const firstCharFromName = words[i][0].toUpperCase()
      shortName += firstCharFromName
      if (i >= 1) {
        i = words.length
      }
    }
    return shortName
  }

  renderUserContainer = (
    userData: UserData,
    handleOpenUser: (user: UserData | undefined) => void,
    hasAdminRights = false,
    userRoles: string[]
  ) => {
    return (
      <div
        key={userData.RowKey}
        className={`user-container ${hasAdminRights ? 'user-containerAdmin' : ''}`}
        onClick={() => hasAdminRights && handleOpenUser(userData)}
      >
        <div className='container-userInfo'>
          <div className='userInfo-img'>
            {userData.thumbnail ? (
              <img referrerPolicy='no-referrer' src={userData.thumbnail} />
            ) : (
              <div className='shortName'>{this.shortNameExtration(userData.username || 'Unknown User')}</div>
            )}
          </div>
          <div className='userInfo-info'>
            <div className='info-name'>{userData.username || 'Unknown User'}</div>
            <div className='info-role'>
              {userRoles.map((role, index) => {
                if (userRoles.length === index + 1) {
                  return i18n.t('administration.userManagement.userRole.' + role)
                }
                return i18n.t('administration.userManagement.userRole.' + role) + ', '
              })}
            </div>
          </div>
        </div>

        {hasAdminRights && (
          <div className='container-icon'>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
              <mask id='mask0_693_12879' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='25'>
                <rect y='0.5' width='24' height='24' fill='#F6F6F6' />
              </mask>
              <g mask='url(#mask0_693_12879)'>
                <path
                  d='M15 19.5L13.6 18.1L18.2 13.5H2V11.5H18.2L13.6 6.9L15 5.5L22 12.5L15 19.5Z'
                  fill='#F6F6F6'
                />
              </g>
            </svg>
          </div>
        )}
      </div>
    )
  }

  renderMyUserContainer = () => {
    const userData = this.props.userContainer.state.user
    const licences = this.props.userContainer.state.licences
    const roles = this.getUserRolesString(licences)
    return (
      <div key='MY_OWN_CONTAINER' className='user-container'>
        <div className='container-userInfo'>
          <div className='userInfo-img'>
            <img
              className='thump'
              referrerPolicy='no-referrer'
              src={userData?.picture ? userData.picture : NoUserImg}
            />
          </div>
          <div className='userInfo-info'>
            <div className='info-name'>{userData?.name}</div>
            <div className='info-role'>
              {roles.map((role, index) => {
                if (roles.length === index + 1) {
                  return i18n.t('administration.userManagement.userRole.' + role)
                }
                return i18n.t('administration.userManagement.userRole.' + role) + ', '
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleSearchInput = (value: string) => {
    this.setState({
      searchInput: value
    })
  }

  renderSearchBarAddUserContainer = () => {
    const userHasAdminRights = this.props.userContainer.state.hasAdminRights
    return (
      <div className='top-container'>
        <div className='container-left'>
          <StaigeInput
            icon={searchIcon}
            onChange={(e) => this.handleSearchInput(e.target.value)}
            placeholder={i18n.t('general.search')}
          />
        </div>
        <div className='container-right'>
          <div style={{ position: 'fixed', marginTop: '36px' }}>
            <StaigeButton
              icon={addUserIcon}
              iconMargin='0 0 0 -4px'
              label={i18n.t('administration.userManagement.userEdit.addUser')}
              disabled={!userHasAdminRights}
              onClick={() => this.props.handleNewUser(true)}
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const renderSearchBarAddUserContainer = this.renderSearchBarAddUserContainer()
    const ownContainer = this.renderMyUserContainer()
    const userHasAdminRights = this.props.userContainer.state.hasAdminRights
    return (
      <div className='list-user'>
        {renderSearchBarAddUserContainer}
        {ownContainer}
        {this.props.user?.map((data: UserData) => {
          const { searchInput } = this.state
          const userRoles = this.getUserRolesString(data.license)

          if (searchInput.length > 0) {
            if (!String(data.username).toLowerCase().includes(searchInput.toLowerCase())) {
              return null
            }
          }

          return this.renderUserContainer(data, this.props.handleOpenUser, userHasAdminRights, userRoles)
        })}
      </div>
    )
  }
}
